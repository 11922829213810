import React from 'react';
import ThankYouStyle from '../containers/Interior/thankyou.style';

const ThankYou = () => {
  return (
    <ThankYouStyle>
        <section id="thankYouSection" className="thankyou-section">
            <div className="container">
                <div className="thankyou-content-area">
                    <img className="logo-img" src="/logo.png" alt="Logo Image" />
                    <div className="thankyou-content-inner">
                        <h2 className="thankyou-title">Thank You For The Request.</h2>
                        <div className="thankyou-desc">Thank you for choosing Radiance Hair Studio for your beauty and hair care needs. Your satisfaction is our ultimate goal, and we look forward to seeing you again soon.</div>
                    </div>                    
                    <div className="thankyou-btn">
                        <a href="https://www.radiancehairstudio.com/" className="btn btn-default">Back to Home <img src="/right-arrow-circled.png" alt="right-arrow" width="17" height="17" /></a>
                    </div>
                </div>                
             </div>
        </section>
    </ThankYouStyle>
  )
}

export default ThankYou;