import styled from "styled-components";

const ThankYouStyle = styled.div`

  .thankyou-section {
    background: #efaa4c;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .thankyou-content-area {
    background: #fff;
    border-radius: 15px;
    padding: 100px 25px;
    text-align: center;
    max-width: 850px;
    margin: auto;    position: relative;
    margin-bottom: 0;
  }
  .thankyou-content-area img {
    margin-bottom: 25px;
  }
  .thankyou-content-inner {
    padding: 0 80px;
  }
  .thankyou-btn {
    display: block;
    margin-top: 35px;
  }
  .thankyou-title {
    font-family: Roboto, sans-serif;
    font-size: 35px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 15px;
    color: rgb(39, 51, 67);
  }
  .thankyou-desc {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: rgb(39, 51, 67);
  }

  .title {
    font-family: Roboto, sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 20px;
    color: rgb(39, 51, 67);
  }
  p,
  ul li {
    color: rgb(78, 88, 101);
    font-size: 15px;
    line-height: 25px;
    font-family: Roboto, sans-serif;
    margin-bottom: 10px;
  }
  .thankyou-btn .btn-default {
    background: #f1cd7e;
    border: 1px solid #f1cd7e;
    border-radius: 3px;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    padding: 20px 40px;
  }
  .thankyou-btn .btn-default img {
    display: inline-block !important;
    margin-left: 15px;
    margin-bottom: 3px;
    vertical-align: middle;
    width: auto !important;
  }
  .thankyou-btn .btn-default:hover {
    background: #ff900b;
    color: #fff;
  }

  @media only screen and (max-device-width: 767px) {

    .thankyou-content-area img {
        width: 170px;
    }

    .thankyou-content-area .thankyou-title {
      font-size: 25px !important;
    }
    .thankyou-desc {
      font-size: 16px !important;
      line-height: 28px !important;
    }
    .thankyou-content-area {
      padding: 25px 25px !important;
    
    }
    .thankyou-btn .btn-default {
      padding: 20px 25px !important;
    }
    .thankyou-section {
      height: auto !important;
      padding-top: 60px !important;
    }
    .thankyou-content-inner {
      padding: 0 !important;
    }
    .thankyou-btn {
      margin-top: 50px !important;
      margin-bottom: 20px !important;
    }
    .thankyou-title {
      margin-bottom: 20px !important;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .thankyou-content-inner {
      padding: 0 !important;
    }
    .thankyou-content-area {
      padding: 60px !important;
      margin: auto 30px !important;
    }
    .thankyou-section {
      height: auto !important;
      padding-top: 60px;
    }
  }
`;

export default ThankYouStyle;
